import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl'

export default class extends Controller {
  static targets = [ 'postsFeatures' ]

  static values = {
    apiToken: String,
    theme: String
  }

  initialize() {
    mapboxgl.accessToken = this.apiTokenValue
    const theme = this.themeValue
    let styleUrl = 'mapbox://styles/mapbox/streets-v12?optimize=true'
    if (theme === 'dark') {
      styleUrl = 'mapbox://styles/mapbox/dark-v10?optimize=true'
    } else if (theme === 'system') {
      const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches
      styleUrl = prefersDarkScheme ? 'mapbox://styles/mapbox/dark-v10?optimize=true' : 'mapbox://styles/mapbox/streets-v12?optimize=true'
    }
    if (theme === 'system') {
      window.matchMedia("(prefers-color-scheme: dark)").addEventListener('change', (event) => {
        this.map.setStyle(event.matches ? 'mapbox://styles/mapbox/dark-v10?optimize=true' : 'mapbox://styles/mapbox/streets-v12?optimize=true')
      })
    }
    let center = JSON.parse(this.postsFeaturesTarget.dataset.postsFeatures).center
    this.map = new mapboxgl.Map({
      container: 'map',
      style: styleUrl,
      // style: 'mapbox://styles/mapbox/standard',
      // config: {
      //   // Initial configuration for the Mapbox Standard style set above. By default, its ID is `basemap`.
      //   basemap: {
      //       // Here, we're setting the light preset to `night`.
      //       lightPreset: 'dawn'
      //   }
      // },
      // TODO: set as default center or get center from request (center of country get from choosen location)
      center: center,
      minZoom: 4.5,
      maxZoom: 17
    })
    // this.map.setCamera({
    //   cameraProjection: "perspective",
    //   tilt: 45,
    //   altitude: 1000
    // })

    // Add zoom and rotation controls to the map.
    this.map.addControl(new mapboxgl.NavigationControl());
    // Add geolocate control to the map.
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      })
    )
  }

  postsFeaturesTargetConnected() {
    this.posts = JSON.parse(this.postsFeaturesTarget.dataset.postsFeatures)

    // TODO: https://turbo.hotwired.dev/handbook/frames#pausing-rendering
    if (this.posts.features.length === 0) {
      this.clearLayers()
    }
    else if (this.map.isStyleLoaded()) {
      this.clearLayers()
      this.loadClusters()
      }
    else {
      this.map.on('load', () => {
        this.loadClusters()
        // this.connectZoomIn()
      })
    }
    this.map.fitBounds(this.posts.bbox, { padding: 10 })
  }

  // TODO: create zoom map find post feature
  connectZoomIn() {
    this.map.on('moveend', () => {
      this.clearLayers()
      this.loadClusters()
      // TODO: map drag search
      // console.log(this.map.getBounds().getSouthWest().toArray())
      // console.log(this.map.getBounds().getNorthEast())
      // this.location = this.map.getBounds().getSouthWest().toArray() + ',' + this.map.getBounds().getNorthEast().toArray()
      // console.log(this.location)
      // let current_url = JSON.parse(this.postsFeaturesTarget.dataset.postsFeatures).current_url
      // Turbo.visit(`${current_url}`)
    })
  }

  clearLayers() {
    const layers = ['clusters', 'cluster-count', 'unclustered-point']
    layers.forEach((layer) => {
      if (this.map.getLayer(layer)) this.map.removeLayer(layer)
    })
    if (this.map.getSource('posts')) this.map.removeSource('posts')
  }

  loadClusters() {
    // Add a new source from our GeoJSON data and
    // set the 'cluster' option to true. GL-JS will
    // add the point_count property to your source data.
    this.map.addSource('posts', {
      type: 'geojson',
      // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
      // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
      data: this.posts,
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    })

    this.map.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'posts',
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        //   * Blue, 20px circles when point count is less than 100
        //   * Yellow, 30px circles when point count is between 100 and 750
        //   * Pink, 40px circles when point count is greater than or equal to 750
        'circle-color': [
          'step',
          ['get', 'point_count'],
          '#bbddee',2,'#88c4e1',4,'#55aad5',6,'#3399cc',10,'#2b80aa'
          // '#bbddee',32,'#88c4e1',64,'#55aad5',128,'#3399cc',256,'#2b80aa' #TODO OTHER COLOR
        ],
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          100,
          30,
          750,
          40
        ]
      }
    })

    this.map.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'posts',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      }
    })

    this.map.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'posts',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': '#ff4040',
        'circle-radius': 6
        // 'circle-stroke-width': 1,
        // 'circle-stroke-color': '#fff'
      }
    })

    // inspect a cluster on click
    this.map.on('click', 'clusters', (e) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
        this.map.getSource('posts').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return;

          this.map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    this.map.on('click', 'unclustered-point', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const link = e.features[0].properties.link;
      // const price = e.features[0].properties.price;
      const full_address = e.features[0].properties.full_address
      // const street = e.features[0].properties.street
      // const img = e.features[0].properties.img

      // const mag = e.features[0].properties.mag;
      // const tsunami =
      // e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`
          <h3>
            <a href= ${link} data-turbo="true" data-turbo-frame="modal_post" data-action="click->modals#openMapPost" >

               ${full_address}
            </a>
          </h3>
        `)
        .addTo(this.map);
    })

    this.map.on('mouseenter', ['clusters', 'unclustered-point'], () => {
      this.map.getCanvas().style.cursor = 'pointer'
    })
    this.map.on('mouseleave', ['clusters', 'unclustered-point'], () => {
      this.map.getCanvas().style.cursor = ''
    })
  }


}

