import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modals"
export default class extends Controller {
  closeModalLogIn(e) {
    e.preventDefault();
    const modal = document.getElementById("modal_log_in");
    modal.innerHTML = "";
    modal.removeAttribute("src");
    modal.removeAttribute("complete");
  }

  closeModalPost(e) {
    e.preventDefault();
    const modal = document.getElementById("modal_post");
    modal.innerHTML = "";
    modal.removeAttribute("src");
    modal.removeAttribute("complete");
  }

  closeModalAddPost(e) {
    e.preventDefault();
    const modal = document.getElementById("modal_add_post");
    modal.innerHTML = "";
    modal.removeAttribute("src");
    modal.removeAttribute("complete");
  }

  closeModalEditPost(e) {
    e.preventDefault();
    const modal = document.getElementById("modal_edit_post");
    modal.innerHTML = "";
    modal.removeAttribute("src");
    modal.removeAttribute("complete");
  }

  closeModalConfirm(e) {
    e.preventDefault();
    const modal = document.getElementById("modal_confirm");
    modal.innerHTML = "";
    modal.removeAttribute("src");
    modal.removeAttribute("complete");
  }

  closeFullScreenGallery(e) {
    e.preventDefault();
    const modal = document.getElementById("modal_full_screen_gallery");
    modal.innerHTML = "";
    modal.removeAttribute("src");
    modal.removeAttribute("complete");
  }

  openMapPost(e) {
    const modal = document.getElementById("toggle_filter");
    modal.setAttribute('style', 'display: none')
  }

  openFilter(e) {
    e.preventDefault();
    const modal = document.getElementById("toggle_filter");
    if (modal.style.display == 'block') {
      modal.setAttribute('style', 'display: none')
    }
    else {
      modal.setAttribute('style', 'display: block')
    }
  }
}
