import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button"]

  connect() {
    if (window.matchMedia('(display-mode: minimal-ui)').matches) {
      this.buttonTarget.style.display = 'none';
      return;
    }

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.buttonTarget.style.display = 'inline-block';
    });

    window.addEventListener('appinstalled', () => {
      this.buttonTarget.style.display = 'none';
    });
  }

  install() {
    this.deferredPrompt?.prompt();
    this.deferredPrompt?.userChoice.finally(() => {
      this.deferredPrompt = null;
      this.buttonTarget.style.display = 'none';
    });
  }
}
