import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.googleComponents = [
      { googleComponent: `country`, id: `country_field` },
      { googleComponent: `administrative_area_level_1`, id: `state_field` },
      { googleComponent: `locality`, id: `city_field` },
      { googleComponent: `sublocality_level_1`, id: `district_field` },
      { googleComponent: `route`, id: `street_field` },
      { googleComponent: `street_number`, id: `street_number_field` }
    ];
  }
  initAutocomplete() {
    const lookup_country_list = ['ua', 'pl'];

    const autocomplete = new google.maps.places.Autocomplete(this.element, {
      fields: ['address_components', 'geometry'],
      componentRestrictions: { country: lookup_country_list }
    });
    google.maps.event.clearInstanceListeners(this.element);

    // Prevent form submission on Enter key
    this.element.addEventListener('keydown', e => e.key === 'Enter' && e.preventDefault());

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      // autocompleteFormField.value = place.name;

      place.address_components.forEach(addressComponent => {
        this.googleComponents.forEach(({ googleComponent, id }) => {
          if (addressComponent.types.includes(googleComponent)) {
            document.getElementById(id).value = addressComponent.long_name;
          }
        });
      });

      if (place.geometry) {
        const { lat, lng } = place.geometry.location;
        document.getElementById('latitude_field').value = lat();
        document.getElementById('longitude_field').value = lng();
      }
    });
  }
}
