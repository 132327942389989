import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['menu', 'filter', 'search', 'user', 'close']
  static values = {
    isMenuOpen: { type: Boolean, default: false },
    isFilterOpen: { type: Boolean, default: false },
    isUserOpen: { type: Boolean, default: false },
    isSearchOpen: { type: Boolean, default: false }
  }

  // COLLAPSE MAIN MENU
  menu() {
    this.hide(this.userTarget)
    // this.hide(this.searchTarget)
    // this.hide(this.filterTarget)
    this.isMenuOpenValue ? this.hide(this.menuTarget) : this.show(this.menuTarget)
    this.isMenuOpenValue ? this.hide(this.closeTarget) : this.show(this.closeTarget)
    this.isMenuOpenValue = !this.isMenuOpenValue
  }

  // COLLAPSE USER MENU
  user() {
    this.hide(this.menuTarget)
    this.hide(this.menuTarget)
    this.isUserOpenValue ? this.hide(this.userTarget) : this.show(this.userTarget)
    this.isUserOpenValue ? this.hide(this.closeTarget) : this.show(this.closeTarget)
    this.isUserOpenValue = !this.isUserOpenValue
  }

  // COLLAPSE FILTER
  filter() {
    this.hide(this.menuTarget)
    this.hide(this.userTarget)
    this.isFilterOpenValue ? this.hide(this.filterTarget) : this.show(this.filterTarget)
    this.isFilterOpenValue ? this.hide(this.closeTarget) : this.show(this.closeTarget)
    this.isFilterOpenValue = !this.isFilterOpenValue
  }

  // COLLAPSE SEARCH
  search() {
    this.hide(this.menuTarget)
    this.hide(this.userTarget)
    this.isSearchOpenValue ? this.hide(this.searchTarget) : this.show(this.searchTarget)
    this.isSearchOpenValue ? this.hide(this.closeTarget) : this.show(this.closeTarget)
    this.isSearchOpenValue = !this.isSearchOpenValue
  }

  // close() {
  //   // console.log('user')
  //   // console.log(this.userTarget)
  //   this.hide(this.closeTarget)
  //   this.isUserOpenValue ? this.hide(this.closeTarget) : this.show(this.closeTarget)
  //   this.isUserOpenValue = !this.isUserOpenValue
  // }

  show(e) {
    e.classList.add("showMenu")
  }

  hide(e) {
    e.classList.remove("showMenu")
  }

}



