// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "chartkick/chart.js"

import { Turbo } from "@hotwired/turbo-rails" // ? or ? // import * as Turbo from "@hotwired/turbo-rails"
export { Turbo }

// import * as cable from "./cable"
// export { cable }

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

window.Turbo = Turbo

// // turbo_stream.action(:redirect, comments_path)
// Turbo.StreamActions.redirect = function() {
//     Turbo.visit(this.target)
// }

// window.initMap = function(...args) {
//   const event = document.createEvent("Events")
//   event.initEvent("google-maps-callback", true, true)
//   event.args = args
//   window.dispatchEvent(event)
// }

window.initAutocomplete = function () {
  const event = new Event('google-maps-callback', {
    bubbles: true,
    cancelable: true,
  })
  window.dispatchEvent(event)
}

