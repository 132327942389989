import { Controller } from '@hotwired/stimulus';
// import Swiper from 'swiper/swiper-bundle';
import Swiper from 'swiper'
import { Navigation,
         Pagination,
         Thumbs,
         Autoplay,
         Keyboard} from 'swiper/modules'

export default class extends Controller {
  connect() {

    // SLIDER THUMBS
    this.galleryThumbs = new Swiper('.gallery-thumbs', {
      slidesPerView: 13,
      freeMode: true,
      spaceBetween: 10,
      watchSlidesProgress: true,
      preventInteractionOnTransition: true,
      direction: 'horizontal'
    });


    // SHOW PAGE SLIDER
    this.swiper = new Swiper('.show-post-slider', {
      modules: [Navigation, Autoplay, Pagination, Keyboard],
      slidesPerView: 1,
      loop: true,
      speed: 200,
      freeMode: true,
      autoplay: {
        delay: 60000
      },
      navigation: {
        nextEl: ".swiper .swiper-button-next",
        prevEl: ".swiper .swiper-button-prev"
      }
    });


    // GALLERY PAGE SLIDER
    this.swiper = new Swiper('.gallery-post-slider', {
      modules: [Navigation, Thumbs, Pagination, Autoplay, Keyboard],
      slidesPerView: 1,
      loop: true,
      speed: 30,
      freeMode: true,
      autoplay: {
        delay: 11000
      },
      // *** thumbs preview
      thumbs: {
        swiper: this.galleryThumbs
      },
      // *** arrows
      navigation: {
        nextEl: ".swiper .swiper-button-next",
        prevEl: ".swiper .swiper-button-prev"
      },
      // *** dotted
      pagination: {
        el: '.swiper .swiper-pagination',
        clickable: true,
        dynamicBullets: true
      },
      // *** keyboard control
      keyboard: {
        enabled: true
      }
    });

  }
}
